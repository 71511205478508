<template>
  <div>
    <div v-if="videoData.length > 0">
      <video
        :id="`video-${i}`"
        :key="i"
        v-for="(video, i) of videoData"
        v-show="videoSrc === video.src"
        width="100%"
        controls
        ref="video"
        preload="auto"
        @timeupdate="($event) => updateVideoTime($event)"
        @pause="videoPaused($event)"
        @play="($event) => videoPlaying($event)"
        :src="video.src"
        style="aspect-ratio: 16/9; background-color: #171A1F;"
      ></video>
    </div>

    <div v-else width="100%">
      No video found. Make sure you uploaded the right analysis file.
    </div>
    <b-form-select
      class="custom-select"
      @change="setVideoIndex($event)"
      :value="videoIndex"
    >
      <b-form-select-option :value="i" :key="i" v-for="(video, i) of videoData">
        {{ video.name }}
      </b-form-select-option>
      <b-form-select-option
        v-if="videoData.length >= 2 && !$route.path.includes('metrics')"
        :value="-1"
      >
        {{ $t("page.results.comparison") }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  props: ["videoTime", "comparisonIndex","class"],
  data() {
    return {
      prevTime: null,
      videoElement: null,
    };
  },

  watch: {
    videoTime(value) {
      if (!this.videoIsPlaying && this.videoElement) {
        this.videoElement.currentTime = value;
        // console.log(value)
      }
    },
    videoIsPlaying(value) {
      if (value) {
        this.videoElement.play();
      } else {
        this.videoElement.pause();
      }
    },
    videoSrc() {
      this.videoElement = document.getElementById(
        "video-" + this.refVideoIndex
      );
    },
  },

  computed: {
    ...mapState("analysis", ["videoData", "videoIndex", "videoIsPlaying"]),
    ...mapGetters("analysis", ["comparison", "video"]),

    videoSrc() {
      return this.videoData[this.refVideoIndex].src;
    },
    refVideoIndex() {
      if (this.comparison) return this.comparisonIndex;
      return this.videoIndex;
    },
  },

  mounted() {
    this.videoElement = document.getElementById("video-0");
    this.initializeVideoEvents();
    if (this.comparison && this.videoData.length < 2) {
      this.setVideoIndex();
    }
  },

  methods: {
    ...mapMutations("analysis", ["SET_CURRENT_VIDEO_INDEX"]),

    setVideoIndex(index) {
      if (index < this.videoData.length) {
        this.SET_CURRENT_VIDEO_INDEX(index);
      }
    },

    setComparisonVideoIndex(index) {},

    videoPaused() {
      this.$emit("play", false);
    },
    videoPlaying() {
      this.$emit("play", true);
    },

    updateVideoTime() {
      this.$emit("videoTimeUpdate", this.videoElement.currentTime);
    },

    initializeVideoEvents() {
      this.$refs.video.forEach((video) => {
        video.addEventListener("play", () => {
          this.$emit("play", true);
        });
        video.ontimeupdate = () => {
          let currentTime = Math.trunc(video.currentTime);
          if (currentTime < 0) {
            currentTime = 0;
          }
          if (this.prevTime !== currentTime) {
            this.$emit("fixedupdate", currentTime);
            this.prevTime = currentTime;
          }
        };
        video.addEventListener(
          "pause",
          () => {
            video.ontimeupdate = null;
            this.$emit("play", false);
          },
          true
        );
      });
    },
  },
};
</script>
